import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { IconButton, Tooltip, Grid } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { Routes, Route, useNavigate, Navigate, Outlet } from "react-router-dom";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import { useLocalization } from "../../contexts/LocalizationContext";
import { useLocation } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import { useApi } from "../../contexts/MenuContext"; // Import the useApi hook
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
} from "@mui/material";
// import { ProtectedRoute } from "../login/ProtectedRoute";
import { useAuth } from "../../contexts/AuthContext"; // Import your AuthContext
const Charities = React.lazy(() => import("../admin/Charities"));
const CharityDetails = React.lazy(() => import("../admin/CharityDetails"));
const Subscribtions = React.lazy(() => import("../admin/Subscribtions"));
const Users = React.lazy(() => import("../admin/Users"));
const AdminDevices = React.lazy(() => import("../admin/AdminDevices"));
const AdminSingleDevice = React.lazy(() =>
  import("../admin/AdminSingleDevice")
);
const CharityUsers = React.lazy(() => import("../charities/CharityUsers"));
const Profile = React.lazy(() => import("../charities/Profile"));
const Dashboard = React.lazy(() => import("../dashboard/Dashboard"));
const Video = React.lazy(() => import("../video/Video"));
const Devices = React.lazy(() => import("../devices/Devices"));
const Initiatives = React.lazy(() => import("../initiatives/Initiatives"));
const SingleDevice = React.lazy(() => import("../devices/SingleDevice"));
const Ads = React.lazy(() => import("../ads/Ads"));
const NotFound = React.lazy(() => import("../login/NotFound"));

const drawerWidth = 240;

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open",
// })(({ theme, open }) => ({
//   transition: theme.transitions.create(["margin", "width"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginRight: drawerWidth,
//   }),
// }));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const activeStyle = {
  backgroundColor: "#f0f0f0", // Adjust as needed
  borderRadius: "4px",
};
export default function Home() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);

  // const [activeMenu, setActiveMenu] = React.useState("dashboard"); // Default active menu
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const { user } = useAuth();
  function ProtectedRoute({ element, ...rest }) {
    return user ? React.cloneElement(element, rest) : <Navigate to="/" />;
  }
  const handleLogout = () => {
    navigate("/logout");
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const location = useLocation(); // Get the location object
  const storedUsername = localStorage.getItem("username");
  const storedPassword = localStorage.getItem("password");
  const storedCharityName = localStorage.getItem("charity_name");

  // Parse the JSON strings to extract the values
  const username = storedUsername ? JSON.parse(storedUsername).username : null;
  const password = storedPassword ? JSON.parse(storedPassword).password : null;
  const charity_name = storedCharityName
    ? JSON.parse(storedCharityName).charity_name
    : null;

  const handleMenuClick = async (link, itemName) => {
    updateActiveMenu(link); // Save the active menu state
    navigate(link); // Navigate to the new link

    try {
      await apiMethod(itemName); // Call the API method
    } catch (error) {
      console.error("Error during menu item click:", error);
    }
  };

  const { activeMenu, updateActiveMenu, apiMethod } = useApi();
  const { locale, setLanguage } = useLocalization();
  const tooltipText = locale === "ar" ? "تسجيل الخروج" : "Logout";
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeLanguage = (lang) => {
    setLanguage(lang); // Set the language
  };
  const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      direction: locale === "en" ? "rtl" : "ltr",
      flexGrow: 1,
      marginRight: -drawerWidth,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      }),
      position: "relative",
      overflow: "hidden",
      overflowY:
        activeMenu === "devices" || activeMenu === "initiatives"
          ? "scroll"
          : "hidden",
    })
  );
  const menuItems = [
    {
      text: locale === "en" ? "Home" : "الصفحة الرئيسية",
      icon: <HomeOutlinedIcon />,
      link: "dashboard",
    },
    {
      text: locale === "en" ? "Devices" : "الأجهزة",
      icon: <DevicesOutlinedIcon />,
      link: "devices",
    },
    {
      text: locale === "en" ? "Advertisements" : "الإعلانات",
      icon: <CampaignOutlinedIcon />,
      link: "ads",
    },
    {
      text: locale === "en" ? "Initiatives" : "المبادرات الخيرية",
      icon: <ListOutlinedIcon />,
      link: "initiatives",
    },
    {
      text: locale === "en" ? "Thank-You Video" : "فيديو الشكر",
      icon: <VideocamOutlinedIcon />,
      link: "thank-you",
    },
  ];
  const setCharityMenu = [
    {
      text:
        locale === "en"
          ? "Profile & Subscriptions"
          : "الملف الشخصي والاشتراكات",
      icon: <AccountBoxOutlinedIcon />,
      link: "profile",
    },
  ];

  const messages = {
    en: `Welcome ${charity_name}`,
    ar: `مرحبا ${charity_name}`,
  };
  return (
    <Box sx={{ display: "flex", height: "100vh", width: "100vw" }}>
      <CssBaseline />
      <MuiAppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: "#352D66",
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            aria-label={tooltipText}
            color="inherit"
            onClick={() => setDialogOpen(true)}
            style={{
              transform: "rotate(180deg)",
            }}
          >
            <LogoutIcon />
          </IconButton>
          <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled button group"
            sx={{
              "& .MuiButtonGroup-grouped": {
                borderColor: "#ffffff", // White border between buttons
              },
              "& .MuiButtonGroup-grouped:not(:last-of-type)": {
                borderRight: `1px solid #ffffff`, // White line between buttons
              },
            }}
          >
            <Button
              value="en"
              sx={{
                backgroundColor: locale === "en" ? "#2C1E4A" : "#352D66",
                ":hover": {
                  backgroundColor: "#2C1E4A",
                },
              }}
              onClick={() => handleChangeLanguage("en")}
            >
              En
            </Button>
            <Button
              value="ar"
              sx={{
                backgroundColor: locale === "ar" ? "#2C1E4A" : "#352D66",
                ":hover": {
                  backgroundColor: "#2C1E4A",
                },
              }}
              onClick={() => handleChangeLanguage("ar")}
            >
              عربي
            </Button>
          </ButtonGroup>
          {/* <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
            onClick={handleMenu}
          >
            <Badge badgeContent={17} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>  */}

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top", // Change to "top" to align the menu from the top with respect to the anchor element
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [10, 20], // Adjust this value to move the menu further down or horizontally
                  },
                },
              ],
            }}
          >
            <MenuItem onClick={handleClose}>
              {locale === "en"
                ? "Al Buraimy Charity Requested an upgrade"
                : "لجنة البريمي تريد ترقية الحساب"}
            </MenuItem>
            <MenuItem onClick={handleClose}>
              {locale === "en"
                ? "Al Seeb Charity Requested an upgrade"
                : "لجنة السيب تريد ترقية الحساب"}
            </MenuItem>
          </Menu>

          <Typography
            variant="h6"
            noWrap
            sx={{ flexGrow: 1 }}
            component="div"
          ></Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </MuiAppBar>

      <Main open={open}>
        <DrawerHeader />

        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route
            path="dashboard"
            element={<Dashboard username={username} password={password} />}
          />
          <Route
            path="devices"
            element={<Devices username={username} password={password} />}
          />
          <Route
            path="devices/:ID"
            element={<SingleDevice username={username} password={password} />}
          />
          <Route
            path="ads"
            element={<Ads username={username} password={password} />}
          />

          <Route
            path="initiatives"
            element={<Initiatives username={username} password={password} />}
          />
          <Route
            path="thank-you"
            element={<Video username={username} password={password} />}
          />
          <Route
            path="charities"
            element={<Charities username={username} password={password} />}
          />
          <Route path="charities/:ID" element={<CharityDetails />} />
          <Route path="packages" element={<Subscribtions />} />
          <Route path="users" element={<Users />} />
          <Route path="charityUsers" element={<CharityUsers />} />
          <Route
            path="adminDevices"
            element={<AdminDevices username={username} password={password} />}
          />
          <Route
            path="adminDevices/:ID"
            element={
              <AdminSingleDevice username={username} password={password} />
            }
          />
          <Route
            path="profile"
            element={<Profile username={username} password={password} />}
          />
          {/* Catch-all route within Home for undefined paths */}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Outlet />
      </Main>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <p>
            {locale === "en"
              ? "Are you sure you want to log out? You will need to log in again to access your account."
              : "هل أنت متأكد أنك تريد تسجيل الخروج؟ ستحتاج إلى تسجيل الدخول مرة أخرى للوصول إلى حسابك"}
          </p>
        </DialogContent>
        <DialogActions
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setDialogOpen(false);
            }}
            color="primary"
            style={{ backgroundColor: "#667085", width: "35%" }}
          >
            إلغاء
          </Button>
          <Button
            variant="contained"
            onClick={handleLogout}
            color="primary"
            style={{ backgroundColor: "#2FDCC7", width: "35%" }}
          >
            تأكيد
          </Button>
        </DialogActions>
      </Dialog>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader sx={{ display: "flex" }}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          <Typography
            variant="h6"
            style={{
              width: "100%",
              fontWeight: "bold",
              textAlign: locale === "en" ? "left" : "right",
            }}
          >
            {messages[locale]}
          </Typography>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item) => (
            <ListItem key={item.text}>
              <ListItemButton
                onClick={() => handleMenuClick(item.link)}
                sx={activeMenu === item.link ? activeStyle : {}}
              >
                {locale === "ar" ? (
                  <>
                    {" "}
                    <ListItemText
                      primary={item.text}
                      sx={{
                        textAlign: locale === "ar" ? "right" : "left",
                        marginRight: "1rem",
                      }}
                    />
                    <ListItemIcon>{item.icon}</ListItemIcon>
                  </>
                ) : (
                  <>
                    <ListItemIcon>{item.icon}</ListItemIcon>

                    <ListItemText
                      primary={item.text}
                      sx={{
                        textAlign: locale === "ar" ? "right" : "left",
                        marginRight: "1rem",
                      }}
                    />
                  </>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {setCharityMenu.map((item) => (
            <ListItem key={item.text}>
              <ListItemButton
                onClick={() => handleMenuClick(item.link)}
                sx={activeMenu === item.link ? activeStyle : {}}
              >
                {locale === "ar" ? (
                  <>
                    {" "}
                    <ListItemText
                      primary={item.text}
                      sx={{
                        textAlign: locale === "ar" ? "right" : "left",
                        marginRight: "1rem",
                      }}
                    />
                    <ListItemIcon>{item.icon}</ListItemIcon>
                  </>
                ) : (
                  <>
                    <ListItemIcon>{item.icon}</ListItemIcon>

                    <ListItemText
                      primary={item.text}
                      sx={{
                        textAlign: locale === "ar" ? "right" : "left",
                        marginRight: "1rem",
                      }}
                    />
                  </>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Divider />
        <List>
          {setAdminMenu.map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => handleMenuClick(item.link)}
                sx={activeMenu === item.link ? activeStyle : {}}
              >
                {locale === "ar" ? (
                  <>
                    {" "}
                    <ListItemText
                      primary={item.text}
                      sx={{
                        textAlign: locale === "ar" ? "right" : "left",
                        marginRight: "1rem",
                      }}
                    />
                    <ListItemIcon>{item.icon}</ListItemIcon>
                  </>
                ) : (
                  <>
                    <ListItemIcon>{item.icon}</ListItemIcon>

                    <ListItemText
                      primary={item.text}
                      sx={{
                        textAlign: locale === "ar" ? "right" : "left",
                        marginRight: "1rem",
                      }}
                    />
                  </>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
    </Box>
  );
}
