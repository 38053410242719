import React, { useState } from "react";
import "./login.css";
import clipart from "../../images/loginClipart.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../contexts/AuthContext";

const MyTextField = styled(TextField)({
  width: "20rem",
  borderRadius: "2rem",
});

export default function Login() {
  const navigate = useNavigate();

  // Local state for input values and error handling
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { login } = useAuth(); // Use the login function from AuthContext

  // Handle login
  // const handleLogin = async () => {
  //   // Basic validation for empty fields
  //   if (!username || !password) {
  //     setError("يرجى تعبئة جميع الحقول");
  //     return;
  //   }

  //   try {
  //     // Send login request using GET with query parameters
  //     const response = await axios.get(
  //       `https://cloud.smart-donate.com/backend/api/login.php`,
  //       {
  //         params: {
  //           username: username,
  //           password: password,
  //         },
  //       }
  //     );

  //     // Assuming success if response is ok, redirect to home page
  //     if (response.data.username === username) {
  //       navigate("/home", {
  //         state: {
  //           username: username,
  //           password: password,
  //         },
  //       });
  //       localStorage.setItem("username", username);
  //       localStorage.setItem("password", password);
  //     } else {
  //       setError("بيانات تسجيل الدخول غير صحيحة");
  //     }
  //   } catch (error) {
  //     console.error("Login error:", error);
  //     setError("حدث خطأ أثناء تسجيل الدخول");
  //   }
  // };
  const handleLogin = async () => {
    // Define the API URL
    const apiUrl = `https://cloud.smart-donate.com/backend/api/login.php`;

    // Create a request payload
    const data = new URLSearchParams();
    data.append("username", username);
    data.append("password", password);
    try {
      // Send a POST request to the API
      const response = await axios.post(apiUrl, data);

      // Check if the API returned a user object (customize this check based on your API response)
      // if (response.data && response.data.username) {
      // Successful login, call the login function from AuthContext
      login(response, username, password);
      navigate("/dashboard");
      // } else {
      // Invalid credentials, you can handle this case as needed
      // console.error("Invalid credentials");
      // }
    } catch (error) {
      // Handle any errors here
      // console.error("Error:", error);
    }
  };
  return (
    <div className="loginContainer">
      <div className="loginClipart">
        <img src={clipart} alt="Login Clipart" />
      </div>
      <div className="loginForm">
        <Typography
          variant="h3"
          style={{ fontFamily: "Poppins", margin: "0", padding: "0" }}
        >
          مرحبا من جديد
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: "Poppins", marginBottom: "3rem", padding: "0" }}
        >
          رجاء أدخل بياناتك
        </Typography>

        <div className="formFields">
          <Typography
            variant="subtitle1"
            style={{ fontFamily: "Poppins", margin: 0, padding: 0 }}
          >
            الإيميل
          </Typography>
          <MyTextField
            id="outlined-basic1"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            variant="outlined"
          />
          <Typography
            variant="subtitle1"
            style={{ fontFamily: "Poppins", padding: 0 }}
          >
            الرمز السري
          </Typography>
          <MyTextField
            id="outlined-basic2"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            variant="outlined"
          />

          {/* Display error message if any */}
          {error && (
            <Typography
              variant="body2"
              style={{ color: "red", marginTop: "1rem" }}
            >
              {error}
            </Typography>
          )}

          <Button
            variant="contained"
            style={{
              width: "20rem",
              backgroundColor: "#352D66",
              height: "3.5rem",
              marginTop: "2rem",
              borderRadius: "0.25rem",
            }}
            onClick={handleLogin}
          >
            تسجيل الدخول
          </Button>
          <Typography
            variant="subtitle1"
            style={{
              fontFamily: "Poppins",
              margin: 0,
              padding: 0,
              color: "#352D66",
            }}
          >
            نسيت كلمة المرور؟
          </Typography>
        </div>
      </div>
    </div>
  );
}
