import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import Logout from "./pages/login/Logout";
import { LocalizationProvider } from "./contexts/LocalizationContext";
// import { ProtectedRoute } from "./pages/login/ProtectedRoute";
import NotFound from "./pages/login/NotFound";
import "./index.css";
import { useAuth } from "./contexts/AuthContext"; // Import your AuthContext
import { ApiProvider } from "./contexts/MenuContext"; // Adjust path as necessary
import { TabsProvider } from "./contexts/TabContext"; // Adjust the path

function App() {
  const { user } = useAuth();

  function ProtectedRoute({ element, ...rest }) {
    return user ? React.cloneElement(element, rest) : <Navigate to="/" />;
  }
  return (
    <TabsProvider>
      <ApiProvider>
        <LocalizationProvider>
          <div className="App">
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path="/*"
                element={<ProtectedRoute element={<Home />} />}
              />

              {/* Redirect root to dashboard */}
              <Route path="/" element={<Navigate to="/dashboard" />} />

              <Route path="/logout" element={<Logout />} />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </LocalizationProvider>
      </ApiProvider>
    </TabsProvider>
  );
}

export default App;
